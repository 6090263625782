import React from 'react';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Slide, ToastContainer } from 'react-toastify';
import TagManager from 'react-gtm-module';
import Router from './routes/Router';
import { persistor, store } from './redux/store';
import i18n from './locales/i18n';
import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const tagManagerArgs = {
    gtmId: 'GTM-T4CXNFW'
}

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <Router />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition={Slide}
            closeButton={false}
            bodyStyle={{ zIndex: 1001 }}
            style={{
              width: 'full',
              marginLeft: 'auto',
              marginRight: 'auto',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            }}
            toastStyle={{
              fontSize: '17px',
              lineHeight: '21px',
              padding: '0 10px',
              minHeight: '60px',
              width: 'fit-content',
              borderRadius: '20px',
              color: 'black',
              fontFamily: 'ginto-light',
            }}
          />
        </BrowserRouter>
      </PersistGate>
    </I18nextProvider>
  </Provider>,
);
