import React from 'react';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

type TextInputProps = {
  checked?: boolean;
  setChecked?: (checked: boolean) => void;
  className?: string;
};

export default function CheckboxInput({
  checked,
  setChecked,
  className,
}: TextInputProps): JSX.Element {
  return (
    <div
      className={twMerge(
        classNames(' h-[25px] w-[25px] cursor-pointer rounded-full flex-shrink-0 border-2 border-black ', {
          'bg-sb-pink': checked,
        }),
        className,
      )}
      onClick={(): void => setChecked?.(!checked)}
    />
  );
}
