import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContentPanel from '../components/layouts/ContentPanel';
import { ticketShopService } from '../services';
import useCrashHandler from '../hooks/useCrashHandler';

export default function NotFoundPage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'page.notFound' });
  const crashHandler = useCrashHandler();

  useEffect( (): void => {
    ticketShopService.checkIfShopIsAvailable(1, 1)
      .then((isAvailable): void => {
        if (isAvailable) {
          navigate('/date?lId=1&tId=1');
        } else {
          navigate('/not-active');
        }
      })
      .catch(crashHandler);
  }, []);
  return (
    <ContentPanel className='lg:mt-[112px]'>
      <h1 className="font-['ginto-bold'] text-[24px]">{t('title')}</h1>
      <p>{t('description')}</p>
    </ContentPanel>
  );
}
