import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { FaCalendar, FaChevronLeft, FaChevronRight, FaXmark } from 'react-icons/fa6';
import classNames from 'classnames';
import { isSameDay, isSameMonth } from 'date-fns';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';
import { useNavigate } from 'react-router-dom';
import ContentPanel from '../components/layouts/ContentPanel';
import useDateFormat from '../hooks/useDateFormat';
import useShop from '../hooks/selectors/useShop';
import Button from '../components/buttons/Button';
import Icon from '../components/icons/Icon';
import {
  absoluteMonthDifference,
  dateMax,
  getAllDaysInMonthAtMidnight,
  isBeforeDay,
  makeTimeIrrelevantDate,
} from '../utils/dateUtils';
import { ticketShopService } from '../services';
import { formatPrice } from '../utils/stringUtils';
import useLanguage from '../hooks/selectors/useLanguage';
import { DefaultURLQuery, Language } from '../types/misc';
import IconButton from '../components/buttons/IconButton';
import useCrashHandler from '../hooks/useCrashHandler';
import useQueryParams from '../hooks/useQuery';
import ExtendedLink from '../routes/ExtendedLink';
import { ORDER_ROUTE } from '../constants';

export default function DatePickingPage(): JSX.Element {
  const { dateOfOrder, ticketTypes } = useShop();
  const format = useDateFormat();
  const { t } = useTranslation('translation', { keyPrefix: 'page.datePicking' });
  const [view, setView] = useState<'CAROUSEL' | 'CALENDAR'>('CAROUSEL');
  const [containerHeight, setContainerHeight] = useState<number | 'auto'>('auto');
  const { dateParam, tId, lId } = useQueryParams<DefaultURLQuery>();
  const navigate = useNavigate();
  const crashHandler = useCrashHandler();

  const dateCarouselRef = useRef<HTMLDivElement>(null);
  const ticketCalendarRef = useRef<HTMLDivElement>(null);

  const categories = Object.entries(ticketTypes).filter(
    ([, tis]): boolean => !!tis.length && tis.some((ti): boolean => !!ti.slots.length),
  );

  useEffect((): void => {
    ticketShopService
      .checkIfShopIsAvailable(lId ?? -1, tId ?? -1)
      .then((isAvailable): void => {
        if (!isAvailable) {
          navigate('/not-active');
        }
      })
      .catch(crashHandler);

    if (dateParam && tId && lId) {
      let date: Date;
      try {
        date = new Date(format(new Date(dateParam), "yyyy-MM-dd'T'HH:mm:ssXXX"));
      } catch (_) {
        return;
      }
      ticketShopService.setDateOfOrderToShop(date);
      navigate(`/order?lId=${lId}&tId=${tId}`);
    }
  }, []);

  useLayoutEffect((): (() => void) => {
    const dateCarousel = dateCarouselRef.current;
    const ticketCalendar = ticketCalendarRef.current;

    const updateHeight = (): void => {
      const height =
        view === 'CALENDAR' ? ticketCalendar?.offsetHeight : dateCarousel?.offsetHeight;
      setContainerHeight(height || 'auto');
    };
    updateHeight();
    const resizeObserver = new ResizeObserver(updateHeight);
    if (dateCarousel) resizeObserver.observe(dateCarousel);
    if (ticketCalendar) resizeObserver.observe(ticketCalendar);

    return (): void => {
      if (dateCarousel) resizeObserver.unobserve(dateCarousel);
      if (ticketCalendar) resizeObserver.unobserve(ticketCalendar);
    };
  }, [view]);

  return (
    <ContentPanel className="overflow-hidden">
      <div className="flex w-full justify-between gap-2 items-center z-[1]">
        <h3 className="font-ginto-bold text-[20px] sm:text-[24px] leading-[24px]">
          {format(dateOfOrder, 'EEEE')} <br /> {format(dateOfOrder, 'dd MMMM')}
        </h3>

        <Button
          variant="white"
          className="border border-black"
          onClick={(): void => setView(view === 'CAROUSEL' ? 'CALENDAR' : 'CAROUSEL')}>
          {view === 'CAROUSEL' ? (
            <>
              {t('monthView')}
              <Icon icon={FaCalendar} />
            </>
          ) : (
            <>
              {t('close')}
              <Icon icon={FaXmark} />
            </>
          )}
        </Button>
      </div>
      <div
        style={{
          height: containerHeight,
        }}
        className="relative transition-all duration-300">
        <div
          ref={dateCarouselRef}
          className={`absolute top-0 left-1/2 transform -translate-x-1/2 w-full transition-all duration-300 ease-in-out ${
            view === 'CAROUSEL' ? 'translate-y-0 opacity-100' : '-translate-y-[200%] opacity-0'
          }`}>
          <DateCarousel />
          <div className="flex justify-end mt-6">
            <ExtendedLink className={!categories.length ? 'disabled-link' : ''} to={ORDER_ROUTE}>
              <Button disabled={!categories.length} variant="pink">
                {t('continue')}
              </Button>
            </ExtendedLink>
          </div>
        </div>
        <TicketCalendar
          ref={ticketCalendarRef}
          onChange={(): void => setView('CAROUSEL')}
          className={`absolute top-0 left-1/2 transform -translate-x-1/2 w-full transition-all duration-300 ease-in-out ${
            view === 'CALENDAR' ? 'translate-y-0 opacity-100' : 'translate-y-[105%] opacity-0'
          }`}
        />
      </div>
    </ContentPanel>
  );
}

interface DateCarouselProps {
  className?: string;
}

const DateCarousel = forwardRef<HTMLDivElement, DateCarouselProps>(
  ({ className }, ref): JSX.Element => {
    const { lowestPrices, dateOfOrder, timeZone } = useShop();

    const lang = useLanguage();
    const format = useDateFormat();
    const crashHandler = useCrashHandler();

    const normalizedToday = makeTimeIrrelevantDate(new Date(), timeZone);
    const dates = Array.from({ length: 5 }, (_, i): Date => {
      const currentDate = new Date(dateOfOrder);
      currentDate.setDate(currentDate.getDate() + (i - 2));
      return currentDate;
    });

    const prevDate = dates[0];
    const nextDate = dates[dates.length - 1];

    useEffect((): void => {
      if(nextDate.getTime() < normalizedToday.getTime()) return;
      ticketShopService
        .getTicketsOverview(dateMax(prevDate, normalizedToday), nextDate)
        .catch(crashHandler);
    }, [dateOfOrder.getTime()]);

    return (
      <>
        <div
          ref={ref}
          className={twMerge('flex w-full gap-2 items-center max-w-sm md:max-w-lg mx-auto', className)}>
          <IconButton
            className="bg-black text-white hidden mr-1 md:flex h-[50px] w-[50px]"
            iconClassName="h-[15px] w-[15px]"
            icon={FaChevronLeft}
            disabled={isBeforeDay(dates[1], normalizedToday)}
            onClick={(): any => ticketShopService.setDateOfOrderToShop(dates[1])}
          />
          {dates.map((d, i): JSX.Element => {
            const disabled =
              isBeforeDay(d, normalizedToday) || lowestPrices[format(d, 'ddMMyyyy')] === undefined;
            const price = isBeforeDay(d, normalizedToday)
              ? '-'
              : lowestPrices[format(d, 'ddMMyyyy')] < 0
              ? '. . .'
              : formatPrice(lowestPrices[format(d, 'ddMMyyyy')], 1);
            return (
              <div
                key={i}
                onClick={(): false | Promise<void> =>
                  !disabled && ticketShopService.setDateOfOrderToShop(d)
                }
                className={twMerge(
                  'flex flex-col w-full h-[85px] gap items-center justify-between p-1 gap-1 mx-auto rounded-sb-sm bg-sb-light-pink py-2',
                  classNames({
                    'bg-sb-purple text-white !rounded-sb-md w-[100px] h-[130px] py-[10px] px-[20px]':
                      isSameDay(dateOfOrder, d),
                    'bg-opacity-10 bg-[#979797]': disabled,
                    'cursor-pointer': !disabled,
                  }),
                )}>
                <p
                  className={classNames(
                    'text-[17px] whitespace-nowrap leading-[17px] font-ginto-bold',
                    {
                      'opacity-20': disabled,
                    },
                  )}>
                  {`${format(d, `dd${isSameDay(dateOfOrder, d) ? ' LLL' : ''}`)}${
                    lang === Language.EN && isSameDay(dateOfOrder, d) ? '.' : ''
                  }`}
                </p>
                <p
                  className={twMerge(
                    'text-[18px] leading-[22px] opacity-20 text-center',
                    classNames({
                      'text-[17px]': price.length > 2,
                      'text-[16px]': price.length > 3,
                      'text-[14px]': price.length > 4,
                      'text-[32px] leading-[32px] font-ginto-bold opacity-100': isSameDay(
                        dateOfOrder,
                        d,
                      ),
                    }),
                  )}>
                  <span className="text-[12px]">Vanaf</span> <br />
                  {price}
                </p>
                <div className="h-[17px]" />
              </div>
            );
          })}
          <IconButton
            className="bg-black text-white hidden md:flex ml-1 h-[50px] w-[50px]"
            iconClassName="h-[15px] w-[15px]"
            icon={FaChevronRight}
            disabled={lowestPrices[format(dates[3], 'ddMMyyyy')] === undefined}
            onClick={(): Promise<void> => ticketShopService.setDateOfOrderToShop(dates[3])}
          />
        </div>
        <div className="flex w-full justify-between items-center md:hidden max-w-sm mx-auto">
          <IconButton
            className="bg-black text-white h-[50px] w-[50px]"
            iconClassName="h-[15px] w-[15px]"
            icon={FaChevronLeft}
            disabled={isBeforeDay(dates[1], normalizedToday)}
            onClick={(): Promise<void> => ticketShopService.setDateOfOrderToShop(dates[1])}
          />
          <IconButton
            className="bg-black text-white h-[50px] w-[50px]"
            iconClassName="h-[15px] w-[15px]"
            icon={FaChevronRight}
            disabled={lowestPrices[format(dates[3], 'ddMMyyyy')] === undefined}
            onClick={(): Promise<void> => ticketShopService.setDateOfOrderToShop(dates[3])}
          />
        </div>
      </>
    );
  },
);

interface TicketCalendarProps {
  className?: string;
  onChange?: (date: Date) => void;
}

const TicketCalendar = forwardRef<HTMLDivElement, TicketCalendarProps>(
  ({ className, onChange }, ref): JSX.Element => {
    const format = useDateFormat();
    const lang = useLanguage();
    const { dateOfOrder, lowestPrices, timeZone } = useShop();
    const normalizedToday = makeTimeIrrelevantDate(new Date(), timeZone);
    const crashHandler = useCrashHandler();

    const [monthIndex, setMonthIndex] = useState<number>(
      absoluteMonthDifference(dateOfOrder, normalizedToday),
    );

    const month = DateTime.now()
      .setZone(timeZone)
      .plus({ months: monthIndex })
      .startOf('day')
      .toJSDate();
    let monthWeeks = getAllDaysInMonthAtMidnight(monthIndex, timeZone);
    const daysOfWeek =
      lang === Language.EN
        ? ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
        : ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];

    useEffect((): void => {
      monthWeeks = getAllDaysInMonthAtMidnight(monthIndex, timeZone);
      ticketShopService
        .getTicketsOverview(
          dateMax(monthWeeks[0], normalizedToday),
          monthWeeks[monthWeeks.length - 1],
        )
        .catch(crashHandler);
    }, [monthIndex]);

    return (
      <div ref={ref} className={twMerge('flex flex-col gap-2', className)}>
        <div className="flex justify-between w-full max-w-[320px] gap-2 mx-auto items-center">
          <IconButton
            disabled={monthIndex === 0}
            onClick={(): void => setMonthIndex(monthIndex - 1)}
            className="bg-black text-white h-[60px] w-[60px]"
            iconClassName="h-[20px] w-[20px]"
            icon={FaChevronLeft}
          />
          <p className="text-[21px] leading-[17px] whitespace-nowrap">
            {format(month, 'LLLL yyyy')}
          </p>
          <IconButton
            onClick={(): void => setMonthIndex(monthIndex + 1)}
            className="bg-black text-white h-[60px] w-[60px]"
            iconClassName="h-[20px] w-[20px]"
            icon={FaChevronRight}
          />
        </div>
        <div className="grid grid-cols-7 gap-x-2 gap-y-2.5 w-fit mx-auto items-center">
          {daysOfWeek.map(
            (d): JSX.Element => (
              <label key={d} className="flex justify-center text-[17px] leading-[22px]">
                {d}
              </label>
            ),
          )}
          {monthWeeks.map((d, i): JSX.Element => {
            const disabled =
              isBeforeDay(d, normalizedToday) || lowestPrices[format(d, 'ddMMyyyy')] === undefined;
            const price = isBeforeDay(d, normalizedToday)
              ? '-'
              : lowestPrices[format(d, 'ddMMyyyy')] < 0
              ? '. . .'
              : formatPrice(lowestPrices[format(d, 'ddMMyyyy')], 1);
            const greyed = !isSameMonth(d, month) || disabled;
            return (
              <div
                onClick={(): void => {
                  if (disabled) return;
                  if (!isSameMonth(d, month))
                    setMonthIndex((prev): number => prev + (d < month ? -1 : 1));
                  onChange?.(d);
                  ticketShopService.setDateOfOrderToShop(d);
                }}
                key={i}
                className={twMerge(
                  'flex flex-col w-fit h-fit min-w-[40px] gap-1 items-center p-2.5 px-1 mx-auto rounded-sb-sm bg-sb-light-pink',
                  classNames({
                    'bg-sb-purple text-white': isSameDay(dateOfOrder, d) && isSameMonth(d, month),
                    'bg-opacity-10 bg-[#979797]': greyed,
                    'cursor-pointer': !disabled,
                  }),
                )}>
                <p
                  className={classNames('text-[17px] leading-[17px] font-ginto-bold', {
                    'opacity-20': greyed,
                  })}>
                  {format(d, 'dd')}
                </p>
                <p
                  className={twMerge(
                    'text-[15px] leading-[22px] opacity-30',
                    classNames({
                      'text-[14px]': price.length > 4,
                      'opacity-100': isSameDay(dateOfOrder, d) && isSameMonth(d, month),
                    }),
                  )}>
                  {price}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  },
);
