import React from 'react';
import ModalLayout from '../layouts/ModalLayout';
import Button from '../buttons/Button';

interface WarningModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  title: string;
  description: string;
  onConfirm: () => void;
  confirmText: string;
  cancelText: string;
}

export default function WarningModal({
  open,
  setOpen,
  title,
  description,
  onConfirm,
  confirmText,
  cancelText,
}: WarningModalProps): JSX.Element {
  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      className="rounded-sb-lg mx-5 bg-white border-sb-pink border min-w-[300px] sm:min-w-[400px] w-fit overflow-hidden">
      <div className='bg-sb-light-pink w-full h-full  flex flex-col gap-6 px-6 py-5'>
        <div className="flex flex-col gap-2">
          <h1 className="font-ginto-bold text-[24px] leading-[24px]">{title}</h1>
          <p className="text-[17px] leading-[17px] max-w-[500px]">{description}</p>
        </div>
        <div className="flex gap-2 flex-col">
          <Button onClick={onConfirm}>{confirmText}</Button>
          <Button onClick={(): void => setOpen(false)}>{cancelText}</Button>
        </div>
      </div>
    </ModalLayout>
  );
}
