import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FaShoppingBasket } from 'react-icons/fa';
import { CSSObjectWithLabel, FormatOptionLabelMeta } from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import ContentPanel from '../components/layouts/ContentPanel';
import useTicketPage from '../hooks/selectors/useTicketPage';
import useCrashHandler from '../hooks/useCrashHandler';
import { SlotPrice, TicketType } from '../types/order';
import IconButton from '../components/buttons/IconButton';
import DropdownInput, { DropdownOption } from '../components/inputs/DropdownInput';
import { TicketPurchaseDto } from '../types/cart';
import { cartService } from '../services';
import useShoppingCart from '../hooks/selectors/useShoppingCart';
import { Language } from '../types/misc';
import useLanguage from '../hooks/selectors/useLanguage';
import useDateFormat from '../hooks/useDateFormat';
import useShop from '../hooks/selectors/useShop';
import { DATE_PICKING_ROUTE } from '../constants';
import { setPageIndex } from '../redux/slices/shopSlice';
import RotterdampasDialogBox from '../components/inputs/RotterdampasDialogBox';
import { RotterdampasDto } from '../types/rotterdampas';
import { getRotterdampassesFromCart } from '../services/cartService';

export default function OrderLandingPage(): JSX.Element | null {
  const page = useTicketPage();
  const crashHandler = useCrashHandler();
  const dispatch = useDispatch();
  const { pageIndex } = useShop();
  const navigate = useNavigate();

  useEffect((): void => {
    if (!page?.length) crashHandler(new Error('REPAIR_FAILED'));
  }, []);

  useEffect((): void => {
    if (page?.length && page?.some((t): boolean => !!t.slots.length)) return;
    if (pageIndex > 0) dispatch(setPageIndex(pageIndex - 1));
    else navigate(DATE_PICKING_ROUTE);
  }, [JSON.stringify(page)]);

  if (!page?.length) return null;
  return (
    <ContentPanel className="gap-2 p-2">
      {page.sort((t1, t2): number => t1.orderPriority - t2.orderPriority).map((t): JSX.Element => (
        <TicketComponent key={t.id} ticket={t} />
      ))}
    </ContentPanel>
  );
}

interface TicketComponentProps {
  ticket: TicketType;
}

function TicketComponent({ ticket }: TicketComponentProps): JSX.Element | null {
  const crashHandler = useCrashHandler();
  const format = useDateFormat();
  const { purchases } = useShoppingCart();
  const lang = useLanguage();
  const { t } = useTranslation('translation', { keyPrefix: 'page.order' });

  const [slot, setSlot] = useState<SlotPrice>();
  const [count, setCount] = useState<number>();
  const inCart = cartService.getAmountForSlotId(slot?.id ?? -1);

  const [isRotterdampasDialogOpen, setIsRotterdampasDialogOpen] = useState(false);
  const handleRotterdampasDialogClose = () : void => {
    setIsRotterdampasDialogOpen(false);
  };

  const handleRotterdampasSubmit = (rotterdampas: RotterdampasDto) : void => {
    if ((!slot && !ticket.alwaysAvailable) || (!count && !ticket.rotterdampas)) return;

    const storedRotterdampasses = getRotterdampassesFromCart();

    // find duplicates and return error when cardId is already found
    const isDuplicated = [...storedRotterdampasses, rotterdampas.cardId ].some((e, i, arr): boolean => arr.indexOf(e) !== i);
    if (isDuplicated) {
      toast.error(t("cardid_duplicated"));
      return;
    }

    setIsRotterdampasDialogOpen(false);

    const purchase: TicketPurchaseDto = {
      ticketTypeId: ticket.id,
      count: 1,
      price: slot?.currentPrice ?? ticket.slots[0].currentPrice,
      slotId: slot?.id ?? ticket.slots[0].id,
      rotterdampasses: [...storedRotterdampasses, rotterdampas.cardId ],
    };

    cartService
      .addNewItemToShoppingCart(purchase)
      .then((): void => {
        setCount(undefined);
        toast.success(t("rotterdampas_applied"));
      })
      .catch(crashHandler);
  }

  const handleSubmit = (): void => {

    if (ticket.rotterdampas) {
      setIsRotterdampasDialogOpen(true);
      return;
    }

    if ((!slot && !ticket.alwaysAvailable) || !count) return;

    const purchase: TicketPurchaseDto = {
      ticketTypeId: ticket.id,
      count,
      price: slot?.currentPrice ?? ticket.slots[0].currentPrice,
      slotId: slot?.id ?? ticket.slots[0].id,
      rotterdampasses: [],
    };

    cartService
      .addNewItemToShoppingCart(purchase)
      .then((): void => {
        setCount(undefined);
        if (inCart + count >= (slot?.ticketsLeft ?? 0)) setSlot(undefined);
      })
      .catch(crashHandler);
  };

  const min = Math.max(
    (ticket.lowBoundTicketCount ?? 0) -
      purchases
        .filter((p): boolean => p.ticketTypeId === ticket.id && p.slotId === slot?.id)
        .reduce((acc, i): number => acc + i.count, 0),
    1,
  );

  const max = Math.min(ticket.highBoundTicketCount || 50, slot?.ticketsLeft || 50) - inCart;
  const range =
    slot || ticket.alwaysAvailable ? Array.from({ length: max - min + 1 }, (_, i): number => i + min) : [];

  if (!ticket.slots.length) return null;
  return (
    <div className="bg-sb-light-blue rounded-sb-md w-full flex flex-col px-4 py-4 gap-4">
      <div className="flex flex-col">
        <p className="font-ginto-bold font-[25px] leading-[22px]">{ticket.name.toLowerCase()}</p>
        {ticket.description && (
          <p className="text-[15px] leading-[21px] opacity-30">
            {ticket.description.toLowerCase()}
          </p>
        )}
      </div>
      <div className="flex items-center w-full gap-2">
        {!ticket.alwaysAvailable && (
          <DropdownInput
            key={slot?.id}
            options={ticket.slots.map((s): DropdownOption<SlotPrice> => ({
              value: s,
              label: `${format(s.startTime, 'HH:mm')}, €${s.currentPrice.toFixed(2)}`,
            }))}
            setValue={setSlot}
            value={slot}
            formatOptionLabel={formatSlotLabel(lang)}
            placeholder={t('chooseTime')}
            styles={{
              container: (baseStyles): CSSObjectWithLabel => ({ ...baseStyles, width: '100%', position: 'relative' }),
              control: (): any => ({
                height: '30px',
                borderWidth: '0px',
                minWidth: '160px',
                width: '100%',
                fontSize: '14px'
              }),
              option: (): any => ({ fontSize: '14px', zIndex: 20 }),
              menu: (baseStyles): CSSObjectWithLabel => ({
                ...baseStyles,
                zIndex: 20,
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                marginTop: '4px',
              }),
              menuList: (baseStyles): CSSObjectWithLabel => ({
                ...baseStyles,
                zIndex: 20,
                maxHeight: '200px',
              }),
            }}
          />
        )}
        <div className="flex gap-2 w-full justify-end">
          {ticket.alwaysAvailable && (
            <p className="h-[38px] text-[15px] flex items-center">
              €{ticket.slots[0]?.currentPrice.toFixed(2)}
            </p>
          )}
          { !ticket.rotterdampas && <DropdownInput
              options={range.map((n): DropdownOption<number> => ({
                value: n,
                label: n.toString(),
              }))}
              isDisabled={(!slot || !range?.length) && !ticket.alwaysAvailable}
              setValue={(v): void | 0 | undefined => v && setCount(v)}
              value={count}
              key={count}
              placeholder="0"
              styles={{
                container: (baseStyles): CSSObjectWithLabel => ({
                  ...baseStyles,
                  width: '100%',
                  maxWidth: ticket.alwaysAvailable ? '100px' : undefined,
                  position: 'relative'
                }),
                placeholder: (baseStyles): CSSObjectWithLabel => ({ ...baseStyles, color: 'black' }),
                control: (): CSSObjectWithLabel => ({
                  height: '30px',
                  width: '100%',
                  borderWidth: '0px',
                  minWidth: '75px',
                  maxWidth: ticket.alwaysAvailable ? '100px' : undefined,
                }),
                menu: (baseStyles): CSSObjectWithLabel => ({
                  ...baseStyles,
                  zIndex: 20,
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  marginTop: '4px',
                }),
                menuList: (baseStyles): CSSObjectWithLabel => ({
                  ...baseStyles,
                  zIndex: 20,
                  maxHeight: '200px',
                }),
              }}
            />
          }
          <IconButton
            icon={FaShoppingBasket}
            onClick={handleSubmit}
            iconClassName="w-4 h-4 sm:ml-2"
            label='voeg toe'
            disabled={(!slot && !ticket.alwaysAvailable) || (!count && !ticket.rotterdampas)}
            className={`${
              (!slot && !ticket.alwaysAvailable) || (!count && !ticket.rotterdampas) ? 'bg-white' : 'bg-sb-purple'
            } disabled:opacity-50 h-[34px] mt-auto mb-[2px] w-[34px] sm:w-[125px]`}
          />
          <RotterdampasDialogBox isOpen={isRotterdampasDialogOpen} onClose={handleRotterdampasDialogClose} onSubmit={handleRotterdampasSubmit} />
        </div>
      </div>
    </div>
  );
}

const formatSlotLabel = (lang: Language) =>
  function (
    { value, label }: DropdownOption<SlotPrice>,
    { context }: FormatOptionLabelMeta<DropdownOption<SlotPrice>>,
  ): JSX.Element {
    const amount = value.ticketsLeft - cartService.getAmountForSlotId(value.id ?? -1);
    return (
      <div
        onClick={(e): false | void => amount === 0 && e.stopPropagation()}
        className={classNames('flex flex-col', {
          'opacity-20': amount === 0 && context === 'menu',
        })}>
        <p>{label}</p>
        <p>
          {context === 'menu' && `${amount} ${lang === Language.EN ? 'available' : 'beschikbaar'}`}
        </p>
      </div>
    );
  };
