import { format, toZonedTime } from 'date-fns-tz';
import _ from 'lodash';
import { TicketShopOverviewDto } from '../types/misc';
import httpService from './httpService';
import { store } from '../redux/store';
import {
  setDateOfOrder,
  setLowestPrices,
  setShop,
  setTicketTypes,
} from '../redux/slices/shopSlice';
import { SlotPrice, TicketType, TicketTypesByCategoryId } from '../types/order';
import { clearCart, setBookingFee } from '../redux/slices/shoppingCartSlice';
import { TicketTypeWithSlotMetadataDto } from '../types/cart';
import { makeTimeIrrelevantDate } from '../utils/dateUtils';

/**
 * Checks if the shop is available at the given location
 * 
 * @param locationId The id of the location
 * @param ticketShopId The id of the ticket shop
 * @returns {Promise<boolean>} Whether the shop is available at the location
 */
export async function checkIfShopIsAvailable(locationId: number, ticketShopId: number): Promise<boolean> {
  return httpService
    .get(`/api/locations/${locationId}/ticket-shops/${ticketShopId}/available`)
    .then((): boolean => true)
    .catch((): boolean => false);
}

/**
 *
 * @param from The date from which to get the overview of lowest prices for tickets per day
 * @param to The data to which to get the overview of lowest prices for tickets per day
 * @returns {Promise<Record<string, number>>} A map of dates to the lowest price for a ticket on that day
 */
export async function getTicketsOverview(from: Date, to: Date): Promise<Record<string, number>> {
  return httpService
    .get<TicketShopOverviewDto>(
      '/api/locations/:locationId/ticket-shops/:ticketShopId/overview/tickets',
      { params: { from, to } },
    )
    .then(({ data }): Record<string, number> => {
      const { name, timeZone, bookingFee, dayLowestPrices, locationName } = data;
      const map = dayLowestPrices.reduce((acc, { date, lowestPrice }): Record<string, number> => {
        const zonedDate = toZonedTime(date, timeZone);
        const key = format(zonedDate, 'ddMMyyyy', { timeZone });
        acc[key] = lowestPrice;
        return acc;
      }, {} as Record<string, number>);

      store.dispatch(setLowestPrices(map));
      store.dispatch(setShop({ name, timeZone, locationName }));
      store.dispatch(setBookingFee(bookingFee));
      const date = store.getState().shop.dateOfOrder;
      const normalizedDate = makeTimeIrrelevantDate(date, timeZone);
      if (normalizedDate.getTime() !== date.getTime())
        store.dispatch(setDateOfOrder(normalizedDate));

      return map;
    });
}

/**
 * Fetches the ticket types for the current date of order
 * @returns {Promise<TicketType[]>} The ticket types for the current date of order
 */
export async function fetchTicketTypes(): Promise<TicketType[]> {
  const { dateOfOrder:date, timeZone } = store.getState().shop;
  if(date.getTime() < makeTimeIrrelevantDate(new Date(), timeZone).getTime()) 
    return Promise.reject(new Error('INVALID_DATE'));
  
  return httpService
    .get<TicketType[]>('/api/locations/:locationId/ticket-shops/:ticketShopId/tickets', {
      params: { date },
    })
    .then(({ data }): TicketType[] => {
      store.dispatch(
        setTicketTypes(_.groupBy(data, (tt): number => tt.categoryId) as TicketTypesByCategoryId),
      );
      return data;
    });
}

/**
 * Sets the date of order to the shop and fetches the ticket types for that date
 * @param date The date to set the order to
 * @returns {Promise<TicketType[]>} The ticket types for the date
 */
export async function setDateOfOrderToShop(date: Date): Promise<void> {
  store.dispatch(setDateOfOrder(date));
  store.dispatch(clearCart());
}

/**
 * Gets the ticket type with the given id stored in the redux store
 * @param id The id of the ticket type to get
 * @returns {TicketType | undefined} The ticket type with the given id or undefined if not found
 */
export function getTicketTypeById(id: number): TicketType | undefined {
  return Object.values(store.getState().shop.ticketTypes)
    .flatMap((types): TicketType[] => types)
    .find((type): boolean => type.id === id);
}

/**
 * Gets the slot price with the given id for the given ticket type
 * @param ticketType The ticket type
 * @param slotId The id of the slot
 * @returns {SlotPrice | undefined} The slot price with the given id for the ticket type or undefined if not found
 */
export function getSlotPriceByTicketTypeSlotId(
  ticketType: TicketType,
  slotId: number,
): SlotPrice | undefined {
  return ticketType.slots.find((slot): boolean => slot.id === slotId);
}

/**
 * Gets the maximum count of tickets that can be bought for the given ticket type and slot
 * @param ticketTypeId The id of the ticket type
 * @param slotId The id of the slot
 * @returns {number} The maximum number of tickets that can be bought
 */
export function getMaxCountForTicketType(ticketTypeId: number, slotId: number): number {
  const ticketMetadata = getTicketTypeWithSlotMetadata(ticketTypeId, slotId);

  return Math.min(
    ticketMetadata?.ticketsLeft ?? Number.MAX_SAFE_INTEGER,
    ticketMetadata?.highBoundTicketCount ?? Number.MAX_SAFE_INTEGER,
  );
}

/**
 * Gets the number of tickets of the given type and slot in the shopping cart
 * @param ticketTypeId The id of the ticket type
 * @param slotId The id of the slot
 * @returns {number} The number of tickets of the given type and slot in the shopping cart
 */
export function getTicketTypeWithSlotMetadata(
  ticketTypeId: number,
  slotId: number,
): TicketTypeWithSlotMetadataDto | undefined {
  const ticketType = getTicketTypeById(ticketTypeId);
  if (!ticketType) return undefined;
  const slot = getSlotPriceByTicketTypeSlotId(ticketType, slotId);
  if (!slot) return undefined;
  return {
    name: ticketType.name,
    slotTime: {
      startTime: slot.startTime.toString(),
      endTime: slot.endTime.toString(),
    },
    lowBoundTicketCount: ticketType.lowBoundTicketCount || 1,
    highBoundTicketCount: ticketType.highBoundTicketCount || Infinity,
    ticketCategoryId: ticketType.categoryId,
    ticketsLeft: slot.ticketsLeft,
  };
}
