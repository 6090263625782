import React, { ComponentProps, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CgSpinnerAlt } from 'react-icons/cg';
import LabeledTextInput from './LabeledTextInput';
import { validateRotterdampas } from '../../services/rotterdampasService';
import { rotterdampasValidators } from '../../utils/validationUtils';
import { RotterdampasDto } from '../../types/rotterdampas';

interface RotterdampasDialogBoxProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (rotterdampas: RotterdampasDto) => void;
}

type RotterdampasFormState = {
  cardId: string;
  pincode: string;
}

export default function RotterdampasDialogBox({ isOpen, onClose, onSubmit }: RotterdampasDialogBoxProps): JSX.Element | null {
    if (!isOpen) return null;

    const { t } = useTranslation('translation', { keyPrefix: 'component.rotterdampas' });
    const [rotterdampas, setRotterdampas] = useState<RotterdampasFormState>({
      cardId: '',
      pincode: ''
    });
    const [errors, setErrors] = useState<Partial<Record<keyof RotterdampasFormState, string>>>({});
    const [validateButtonDisabled, setValidateButtonDisabled] = useState<boolean>(true);
    const [loadingState, setLoadingState] = useState<boolean>(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      const { name, value } = event.target;
      setRotterdampas((prev): RotterdampasFormState => ({
        ...prev,
        [name]: value,
      }));
    };

    useEffect(() : void => {
      if (!rotterdampas.cardId || !rotterdampas.pincode) {
        setValidateButtonDisabled(true);
      } else {
        setValidateButtonDisabled(false);
      }
    }, [rotterdampas]);

    const handleRotterdampasValidate = (event: React.FormEvent<HTMLFormElement>) : void => {
      event.preventDefault();

      if(loadingState) return;
      setLoadingState(true);

      // add zeros if pass number is less than 6 characters
      rotterdampas.cardId = `000000${rotterdampas.cardId}`;
      rotterdampas.cardId = rotterdampas.cardId.substring(rotterdampas.cardId.length - 6);

      const newErrors: Partial<Record<keyof RotterdampasFormState, string>> = {
        cardId: rotterdampasValidators.cardId(rotterdampas.cardId),
        pincode: rotterdampasValidators.pincode(rotterdampas.pincode),
      };

      const hasErrors = Object.values(newErrors).some((error) : boolean => error !== undefined && error !== '');

      if (hasErrors) {
        setErrors(newErrors);
        setLoadingState(false);
        return; 
      }

      validateRotterdampas({
        cardId: rotterdampas?.cardId,
        pincode: rotterdampas?.pincode
      }).then((result) : void => {
        if (result.succeeded) {
          onSubmit(rotterdampas);
        } else {
          toast.error(t(`status.${result.message}`));
        }
      }).catch((error) : void => {
        toast.error(error);
      }).finally((): NodeJS.Timeout => setTimeout(() : void => setLoadingState(false), 1000));
    };

    const getInputProps = (
      name: keyof RotterdampasFormState,
    ): ComponentProps<typeof LabeledTextInput> => ({
      name,
      label: t(`label.${name}`),
      value: rotterdampas?.[name],
      onChange: handleChange,
      errorMessage: errors[name],
      valid: errors[name] === undefined ? undefined : !errors[name],
      onBlur: (): void => {
        if (!rotterdampas?.[name] && errors[name] === undefined) return;
        setErrors((prev) : Partial<Record<keyof RotterdampasFormState, string>> => ({
          ...prev,
          [name]: rotterdampasValidators[name](rotterdampas?.[name] ?? ''),
        }));
      },
      mandatory: true,
    });
  
    return (
      <form onSubmit={handleRotterdampasValidate}>
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg">
            <h2 className="text-lg font-ginto-bold mb-4">{t("dialog_title")}</h2>
            <p className='mb-4'>
              <h2>{t('howToUse')}</h2>
              <ul className='list-decimal ml-6 mb-4'>
                <li>{t('enterCardNumber')}</li>
                <li>{t('enterPin')}</li>
                <li>
                  <Trans i18nKey="component.rotterdampas.noPin">
                    <a
                      className='font-medium text-blue-600 dark:text-blue-500 hover:underline'
                      href='https://www.rotterdampas.nl/pincode-aanmaken'
                      target='_blank'
                      rel='noreferrer'
                    >
                      hier
                    </a>
                  </Trans>
                </li>
                <li>{t('clickAdd')}</li>
                <li>{t('ticketAdded')}</li>
              </ul>
              <p>{t('enterCardAndPin')}</p>
            </p>
            <LabeledTextInput {...getInputProps('cardId')}/>
            <LabeledTextInput {...getInputProps('pincode')} type='password' autoComplete='new-password'/>
            <div className="mt-6 flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-full hover:bg-gray-100"
                type='button'
                onClick={(): void => onClose()}
              >
                {t("cancel_button")}
              </button>
              <button
                className={`flex items-center px-4 py-2 bg-sb-pink text-white rounded-full ${!validateButtonDisabled ? 'hover:bg-sb-light-pink hover:text-gray-800' : ''} disabled:opacity-20 disabled:hover:bg-opacity-100`}
                type='submit'
                disabled={validateButtonDisabled || loadingState}
              >
                { loadingState && <CgSpinnerAlt className="animate-spin mr-3" size={18} />} {t("validate_button")}
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }